
import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  name: "RequestMentoringModal",
  props: {
    mentor: Object,
    mentee: Object
  },
  data() {
    return {
      maxTitleChars: 200 as number,
      loading: false as boolean,
      errors: {} as any,
      data: {
        description: "Hello, I am interested in a personalized mentoring program with you." as string,
        date: "" as string,
        price: null as null | number
      }
    };
  },
  computed: {
    remainingTitleCharacters(): number {
      return this.maxTitleChars - this.data.description.length;
    }
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        await this.$store.dispatch("program/CREATE_PROGRAM_REQUEST", {
          ...this.data,
          date: moment(this.data.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
          mentor_id: this.mentor.id
        });
        (this as any).$alertify.success("Your request submitted successfully");
        this.$bvModal.hide("request-program-mentoring");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error.message);
        }
      } finally {
        this.loading = false;
      }
    },
    selectDate(date: string) {
      this.data.date = date
        .split("-")
        .reverse()
        .join("-");
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
